.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
    

}
.Footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: 10rem;

}

.social-icons{
    display: flex;
    gap: 4rem;
}
.social-icons>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
    cursor: pointer;
    
}