.Header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}

.header-menu{
    display: flex;
    gap: 4rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
}


@media screen and (max-width: 768px) {

    .logo{
        width: 7rem;
        height: 2rem;
    }
    .Header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
        
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    
}