.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;

}
.program-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;}
    .program-categories{
        display: flex;
        gap: 4rem;
    }
    .category{
        display: flex;
        flex-direction: column;
        background-color: gray;
        padding: 2rem;
        color: white;
        gap: 1rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 15px;
        
    }
    .category>:nth-child(1){
        font-size: 2rem;
        height: 2rem;
        width: 2rem;
        fill: white;
    }
    .category>:nth-child(2){
        font-size: 1rem;
        font-weight: bold;
    }
    .category>:nth-child(2){
        font-size: 0.9em;
        line-height: 25px;
    }
    .join-now{
        display: flex;
        align-items: center;
        gap: 2rem;
    }
    .join-now>img{
        width: 1rem;
    }
.category:hover{
    background: var(--planCard);
    cursor: pointer;
} 

@media screen and (max-width: 768px) {
    
    .program-header{
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        font-size: x-large;
        margin-top: 2rem;
    }
    .program-categories{
        flex-direction: column;
        gap: 1rem;
        
    }
     .join-now{
        justify-content: center;
    }
    .join-now>img{
        width: .8rem;
    }/*
    .join-now>:nth-child(2){
        display: none;
    } */

    
}